import React, { useState, useEffect } from 'react';
import { withStyles } from 'shine-ui-react';
import { ShineOrders } from 'shine-products-v2';
import {
    sendErrorMessage, getOrder, approveOrder, sendApprovalConfirmation,
} from './ApiActions';
import Loader from './Loader';

import {
    ApprovalPageContainer,
    ApprovalReceivedBox,
    NoOrderFoundBox,
    ErrorBox,
    MoreQuestionsBox,
    GotQuestionsBox,
} from './ApprovalPage';

import ApprovalForm from './ApprovalForm';

const styles = () => ({
    root: {},
});

const preApprovalStatuses = [
    'new',
    'proofing',
    'approval pending',
];

const approvedStatuses = [
    'approved',
    'printing',
    'shipped',
];

function App() {
    const [order, updateOrder] = useState({});
    const [loading, updateLoading] = useState(true);
    const [omVersion, updateOMVersion] = useState('1');
    const [didError, setDidError] = useState(false);

    function receiveOrder(result, version) {
        try {
            const params = result.data;
            const error = result.data.error;
            if (process.env.NODE_ENV !== 'production') {
                console.log(result);
            }
            if (error) {
                console.error(error);
            } else {
                let newOrder;
                if (version === '1') {
                    newOrder = ShineOrders.instance().fromVersionOne({ ...params.order, packages: params.packages });
                } else {
                    newOrder = ShineOrders.instance().make(params);
                }
                updateOrder(newOrder);
            }
        } catch (error) {
            setDidError(true);
            sendErrorMessage(error);
        }
        updateLoading(false);
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const orderId = params.get('orderId') || null;
        const omver = params.get('omver') || '1';
        if (omver) {
            updateOMVersion(omver);
        }
        if (orderId) {
            getOrder(orderId, omver).catch((err) => {
                console.error(err.message);
            }).then((o) => receiveOrder(o, omver));
        } else {
            updateLoading(false);
        }

        return () => updateOrder({});
    }, []);

    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    const handleSubmit = () => {
        approveOrder(order.id, omVersion)
            .then((o) => receiveOrder(o, omVersion))
            .then(() => sendApprovalConfirmation(order.id, omVersion))
            .then(scrollToTop);
    };

    let body = null;

    if (loading) {
        body = <Loader />;
    } else if (didError) {
        body = (
            <ErrorBox />
        );
    } else if (order && (preApprovalStatuses.includes(order.status))) {
        body = <ApprovalForm order={order} onSubmit={handleSubmit}/>;
    } else if (order && (approvedStatuses.includes(order.status))) {
        body = (
            <React.Fragment>
                <ApprovalReceivedBox />
                <MoreQuestionsBox />
            </React.Fragment>
        );
    } else {
        body = (
            <React.Fragment>
                <NoOrderFoundBox />
                <GotQuestionsBox />
            </React.Fragment>
        );
    }

    return (
        <ApprovalPageContainer>
            {body}
        </ApprovalPageContainer>
    );
}

export default withStyles(styles)(App);
