import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Loader } from 'shine-ui-react';

const styles = () => ({
    root: {
        opacity: 0.5,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
});

export const UnstyledLoader = (props) => {
    const { classes } = props;
    return (
        <div className={classes.root}>
            <Loader color="secondary"/>
        </div>
    );
};

UnstyledLoader.defaultProps = {
    classes: {},
};

UnstyledLoader.propTypes = {
    classes: PropTypes.any,
};

export default withStyles(styles)(UnstyledLoader);
