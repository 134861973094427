import firebase from 'firebase/app';
import 'firebase/functions';

console.log('process.env.REACT_APP_DATABASE_ENVIRONMENT', process.env.REACT_APP_DATABASE_ENVIRONMENT);
const useProductionDatabase = process.env.REACT_APP_DATABASE_ENVIRONMENT === 'production';

const firebaseConfig = useProductionDatabase
    ? {
        apiKey: 'AIzaSyA_AUuAyl1K5xt0j1RaQghr7aGM1ovbIy0',
        authDomain: 'shine-approvals.firebaseapp.com',
        databaseURL: 'https://shine-approvals.firebaseio.com',
        projectId: 'shine-approvals',
        storageBucket: 'shine-approvals.appspot.com',
        messagingSenderId: '22567154311',
        appId: '1:22567154311:web:e2d15f772b2515f4',
    } : {
        apiKey: 'AIzaSyB4NNCOSrUBiemB9hPNo3J_QzbfBRcrxfQ',
        authDomain: 'shine-approvals-dev.firebaseapp.com',
        databaseURL: 'https://shine-approvals-dev.firebaseio.com',
        projectId: 'shine-approvals-dev',
        storageBucket: 'shine-approvals-dev.appspot.com',
        messagingSenderId: '830805177411',
        appId: '1:830805177411:web:28c7c211f26f53f0',
    };

const fire = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
// fire.functions().useFunctionsEmulator('http://localhost:5000');
export default fire;
