import React from 'react';
import PropTypes from 'prop-types';

export class ErrorBoundary extends React.Component {
    constructor() {
        super();
        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error, info) {
        console.warn(error, info);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <p>Oops! Something went wrong here...</p>;
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.any,
};

export default ErrorBoundary;
