import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SampleProduct from 'shine-products-v2/build/products/interfaces/SampleProduct';
import SamplePaperProduct from 'shine-products-v2/build/products/interfaces/SamplePaperProduct';
import {
    withStyles, Box, Checkbox, Typography, Button,
} from 'shine-ui-react';
import clsx from 'clsx';
import uniq from 'lodash/uniq';
import ErrorBoundaryComponent from './ErrorBoundary';
import { ShippingAddresses, Shipments } from './Shipping';
import ProductDetails from './ProductDetails';

function excludeSamples(product) {
    return !Object.prototype.isPrototypeOf.call(SampleProduct.prototype, product)
        && !Object.prototype.isPrototypeOf.call(SamplePaperProduct.prototype, product);
}
const styles = (theme) => ({
    root: {
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        position: 'relative',
    },
    grow: {
        flex: 1,
    },
    narrow: {
        maxWidth: 700,
        margin: [0, 'auto'],
    },
    shippingInformation: {
        width: '100%',
        margin: '1rem 0px',
        '@media (min-width: 600px)': {
            maxWidth: 250,
        },
    },
    orderDetails: {
        width: '100%',
        margin: '1rem 0px',
        '@media (min-width: 600px)': {
            paddingRight: '1rem',
            width: '50%',
        },
    },
    productDetails: {
        '&:not(:last-child)': {
            marginBottom: '1rem',
        },
    },
    spacer: {
        height: '1rem',
    },
    wide: {
        maxWidth: 1000,
        margin: [0, 'auto'],
    },
    greyLine: {
        top: 0,
        left: 0,
        width: '100%',
        borderTop: `1px solid ${theme.palette.grey.light}`,
        marginBottom: 32,
    },
    centered: {
        width: '100%',
        textAlign: 'center',
    },
    heading: {
        letterSpacing: 'normal',
        marginBottom: 32,
    },
    label: {
        letterSpacing: '0.05rem',
        lineHeight: '1.5rem',
        fontStyle: 'italic',
    },
    primary: {
        fontSize: '0.95rem',
    },
    secondary: {
        fontSize: '0.9rem',
        fontWeight: 500,
        fontFamily: theme.typography.fontFamily.sans,
    },
    smaller: {
        fontSize: '0.8rem',
    },
    checkbox: {
        marginBottom: '2rem',
    },
    button: {
        margin: '32px 0px 64px',
        width: '100%',
        '@media (max-width: 768px)': {
            fontSize: '1.1rem',
        },
    },
    error: {
        color: 'red',
        marginBottom: '2rem',
        fontStyle: 'italic',
        fontWeight: 'bold',
    },
});

export const UnstyledApprovalForm = (props) => {
    const { classes, order, onSubmit } = props;

    let unique_quantities = false;
    order.products
        .filter(excludeSamples).forEach((product) => {
            const component_quantities = product.components.map(({ quantity }) => quantity).filter((q) => q);
            if (uniq(component_quantities).length > 1) {
                unique_quantities = true;
            }
        });

    const checkBoxContents = [
        {
            index: 0,
            primary: order.printable_files
                ? 'I confirm I am only interested in digital files and understand no hard copy versions will be sent to me.'
                : 'This is the correct shipping address, printing speed, and shipping service.',
        },
        {
            index: 1,
            primary: 'The paper type is correct.',
        },
        {
            index: 2,
            primary: 'The components listed are correct.',
        },
        {
            index: 3,
            primary: 'The quantities listed are correct.',
            secondary: <p className={classes.smaller}>Requests for more items after the order is approved will be at an additional cost.
                <br />Refunds will not be issued for misordered stationery after approval.
                {unique_quantities && <span style={{ color: 'red' }}>
                    <br />Alert: Quantities do not match. If this is unintentional, please contact your designer immediately!
                </span>}
            </p>,
        },
        {
            index: 4,
            primary: 'I\'ve received a proof of each printable item listed above.',
        },
        {
            index: 5,
            primary: 'I\'ve reviewed the layout, wording, spelling, design, and color of each provided proof.',
            secondary: 'I confirm that all proofs in the most recent round are correct as shown and exactly how I want them.',
        },
        {
            index: 6,
            primary: 'I understand that once I approve the proofs, my order will immediately begin printing.',
            secondary: 'Changes I request after approval will be subject to reprint charges at my expense.',
        },
        {
            index: 7,
            primary: <span>I understand that once my order is approved,
                 Shine is not responsible for any errors related to the
                  proofing process on the final product, <u>no matter where they originated.</u></span>,
            secondary: 'Reprints I request due to errors approved during proofing will be at my expense.',
        },
    ];

    const [checkboxes, updateCheckboxes] = useState(checkBoxContents.map(() => false));
    const [loading, updateLoading] = useState(false);

    const handleSubmit = () => {
        updateLoading(true);
        if (process.env.NODE_ENV !== 'production') {
            setTimeout(onSubmit, 1000);
        } else {
            onSubmit();
        }
    };

    // if all boxes are checked, form is valid.
    const allBoxesChecked = checkboxes.every((a) => a === true);
    const clientHasPaid = order.balance >= 0;
    const valid = allBoxesChecked; // && clientHasPaid;

    const handleCheckbox = (id) => () => {
        const newCheckboxes = checkboxes.slice();
        newCheckboxes[id] = !newCheckboxes[id];
        updateCheckboxes(newCheckboxes);
    };

    const formatCheckboxes = (box, index) => {
        const label = (
            <React.Fragment>
                <Typography
                    variant='body2'
                    className={
                        clsx(
                            classes.label,
                            classes.primary,
                        )
                    }>
                    {box.primary}
                </Typography>

                {box.secondary && <Typography
                    component='div'
                    className={
                        clsx(
                            classes.label,
                            classes.secondary,
                        )
                    }>
                    {box.secondary}
                </Typography>
                }
            </React.Fragment>
        );
        return (
            <div
                className={classes.checkbox}
                key={index}
                data-cy='checkbox'
            >
                <Checkbox
                    label={label}
                    onChange={handleCheckbox(box.index)}
                    value={checkboxes[box.index] || false}
                />
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.narrow}>
                <div className={classes.centered}>
                    <Typography variant='h1' className={classes.heading} gutterBottom>Approval For Printing</Typography>

                    {!clientHasPaid && <React.Fragment>
                        <Typography className={classes.error} component='p'>
                            It looks like there’s an outstanding balance on your order!
                            Orders cannot move into production until payment has been completed, and this could affect your arrival date.
                        </Typography>
                        <Typography className={classes.error} component='p'>
                            If you have just paid your invoice, you may disregard this message.
                        </Typography>
                    </React.Fragment>
                    }
                </div>

                <div className={classes.flex}>
                    <div className={classes.shippingInformation}>
                        <Typography variant='h4' align='left' gutterBottom><strong>Shipping Address</strong></Typography>
                        <ErrorBoundaryComponent>
                            <ShippingAddresses order={order} />
                        </ErrorBoundaryComponent>
                    </div>
                    <div className={classes.shippingInformation}>
                        <Typography variant='h4' align='left' gutterBottom><strong>Printing & Shipping</strong></Typography>
                        <ErrorBoundaryComponent>
                            <Shipments order={order} />
                        </ErrorBoundaryComponent>
                    </div>
                </div>
                <div className={classes.spacer} />
                <div className={classes.flex}>
                    {
                        // checkbox 1
                        checkBoxContents.slice(0, 1).map(formatCheckboxes)
                    }
                    <div className={classes.grow} />
                </div>
            </div>
            <div className={classes.wide}>
                <div className={classes.greyLine} />
                <div className={classes.flex}>
                    <div className={classes.orderDetails}>
                        <Typography variant='h4' align='left' gutterBottom><strong>Order Details</strong></Typography>
                        <ErrorBoundaryComponent>
                            {
                                order.products.filter(excludeSamples).map((product, index) => <ProductDetails
                                    className={classes.productDetails}
                                    key={product.type + index}
                                    product={product}
                                    ignoreQuantity={order.printable_files} />)
                            }
                        </ErrorBoundaryComponent>
                    </div>
                    <div className={classes.orderDetails}>
                        {
                            // checkboxes 2 - 5
                            checkBoxContents.slice(1, 5).map(formatCheckboxes)
                        }
                    </div>
                </div>
                <div className={classes.greyLine} />
            </div>
            <div className={classes.narrow}>
                {
                    // checkboxes 6 - 8
                    checkBoxContents.slice(5).map(formatCheckboxes)
                }
                <Box body={
                    <Typography component='p'>
                        Do not approve if you need to make any changes at all to your order—contact
                        your designer instead. Once final approval has been received,
                    your order will begin production immediately and no further adjustments can be made. <strong><em>
                            As noted above, you will be charged a reprint fee, as well as any other applicable fees,
                    for changes requested after proof approval.</em></strong>
                    </Typography>
                } />
                <Button
                    tabIndex="0"
                    primary
                    data-cy={valid ? 'approval-button' : 'disabled-approval-button'}
                    disabled={!valid}
                    className={classes.button}
                    loading={loading}
                    onClick={handleSubmit}
                >
                    {'NO CHANGES NEEDED. I APPROVE. LET\'S DO THIS!'}
                </Button>
            </div>
        </div>
    );
};

UnstyledApprovalForm.defaultProps = {
    classes: {},
    onSubmit: () => { },
};

UnstyledApprovalForm.propTypes = {
    classes: PropTypes.any,
    onSubmit: PropTypes.func.isRequired,
    order: PropTypes.any.isRequired,
};

export default withStyles(styles)(UnstyledApprovalForm);
