import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ThemeProvider from './ThemeProvider';
import * as serviceWorker from './serviceWorker';
import { sendErrorMessage } from './ApiActions';

require('url-search-params-polyfill');
require('es6-promise').polyfill();
require('isomorphic-fetch');

require('./shine');

window.onerror = function handleError(msg, url, lineNo, columnNo, error) {
    const message = [
        'Message: ' + msg,
        'URL: ' + url,
        'Line: ' + lineNo,
        'Column: ' + columnNo,
        'Error object: ' + JSON.stringify(error),
        'Browser: ' + navigator.userAgent,
    ].join(' - ');
    sendErrorMessage(message); // send your string somewhere..could just post to a firebase function that records the string.
    return false;
};

ReactDOM.render(
    <ThemeProvider>
        <App />
    </ThemeProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
