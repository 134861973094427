import fire from './fire';

export function getOrder(orderId, version) {
    if (!orderId) {
        throw new Error('orderId is required');
    }
    if (window.Cypress) {
        return new Promise((res) => {
            // eslint-disable-next-line
            const result = { data: require('./test.json').orders[orderId] };
            res(result);
        });
    }
    let get;
    if (version === '1') {
        get = fire.functions().httpsCallable('order_old');
    } else {
        get = fire.functions().httpsCallable('order');
    }
    console.log('fetching', orderId, version);
    return get(orderId);
}

export function sendErrorMessage(message) {
    const sendError = fire.functions().httpsCallable('errors');
    return sendError({ message });
}

export function approveOrder(orderId, version) {
    if (window.Cypress) {
        // eslint-disable-next-line
        const result = { data: require('./test.json').orders[orderId] };
        result.data.status = 'approved';
        return new Promise((res) => { res(result); });
    }

    let approve;
    if (version === '1') {
        approve = fire.functions().httpsCallable('submit_old');
    } else {
        approve = fire.functions().httpsCallable('submit');
    }
    return approve(orderId);
}
export function sendApprovalConfirmation(orderId, version) {
    if (version === '1' || window.Cypress) {
        return Promise.resolve();
    }

    const fn = fire.functions().httpsCallable('sendApprovalConfirmation');
    return fn(orderId);
}
