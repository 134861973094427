import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isUndefined from 'lodash/isUndefined';
import startCase from 'lodash/startCase';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';

import { withStyles, Typography } from 'shine-ui-react';
import { ShineShipping } from 'shine-products-v2';

const dateFormat = 'ddd MMM DD YYYY';

const labels = {
    small_shipment: 'Stationery',
    letterpress_shipment: 'Stationery',
    large_shipment: 'Large Signs',
    envelopes_shipment: 'Envelopes',
};

const styles = () => ({
    root: {},
    shipmentHeaderRow: {
        marginTop: '1.4rem',
    },
    address: {
        lineHeight: '1.3rem',
    },
    label: {
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
});

export const UnstyledShippingAddresses = ({ classes, order }) => {
    if (isUndefined(order)) {
        return null;
    }

    const all_shipments = ShineShipping.instance().defaultShipments(order, { clientFacing: true });
    let shipments = [];
    if (order.has_letterpress) {
        shipments = all_shipments.filter(({ designation }) => designation !== 'small_shipment');
    } else {
        shipments = all_shipments;
    }
    const shipping_addresses = shipments.map((shipment) => shipment.shipping_address);
    const unique_shipping_addresses = uniqBy(shipping_addresses, JSON.stringify);

    function formatAddress({
        firstname, lastname, address1, address2, city, state, zip, country,
    }) {
        if (firstname === '' || address1 === '' || city === '') {
            return null;
        }
        const rows = [
            `${firstname} ${lastname}`,
            address1,
            address2,
            `${city}, ${state} ${zip}`,
            country,
        ];
        return rows.filter((a) => !!a).map((str) => <Typography key={str} className={classes.row}>{str}</Typography>);
    }

    return <div>
        {
            unique_shipping_addresses.length === 1
                ? <div className={classes.address}>
                    {formatAddress(unique_shipping_addresses[0])}
                </div>
                : unique_shipping_addresses.map((address) => {
                    // find which shipments have this address
                    const shipmentDesignations = shipments
                        .filter((shipment) => isEqual(JSON.stringify(shipment.shipping_address), JSON.stringify(address)))
                        .map(({ designation }) => designation);

                    // assign labels
                    const formattedShipmentLabels = shipmentDesignations.map((label) => labels[label]);

                    return (
                        <div className={classes.address} key={shipmentDesignations.join(', ')}>
                            <Typography className={classes.label}>{formattedShipmentLabels.join(', ')}</Typography>
                            {formatAddress(address)}
                        </div>
                    );
                })
        }
    </div>;
};

UnstyledShippingAddresses.defaultProps = {
    classes: {},
};

UnstyledShippingAddresses.propTypes = {
    classes: PropTypes.any,
    order: PropTypes.any.isRequired,
};
export const ShippingAddresses = withStyles(styles)(UnstyledShippingAddresses);

export const UnstyledShipments = (props) => {
    const { classes, order } = props;

    let printing_type = order.printing_type;
    if (printing_type === 'STD') {
        printing_type = 'Standard';
    }
    const printSpeedText = startCase(printing_type);
    const shipSpeedText = order.shipping_service;

    const shipments = ShineShipping.instance().defaultShipmentsCollated(order, { clientFacing: true });

    const extraEnvelopeShipment = !!shipments.envelopes_shipment;
    const largeSignsShipment = !!shipments.large_shipment;
    const moreThanOneShipment = (extraEnvelopeShipment || largeSignsShipment);

    const extraShipmentLabels = [];
    if (extraEnvelopeShipment) {
        extraShipmentLabels.push('Envelopes');
    }
    if (largeSignsShipment) {
        extraShipmentLabels.push('Large Signs');
    }

    let printingPlusShipDate;
    let printingPlusArrivalDate;

    // this is for the gotcha case:
    // almost all orders have a small shipment,
    // except for those that are _just_ large signs.
    if (!shipments.small_shipment && shipments.large_shipment) {
        printingPlusShipDate = shipments.large_shipment.ship_by_date;
        printingPlusArrivalDate = ShineShipping.instance().estimateArrivalDate(shipments.large_shipment, order);
    } else if (shipments.small_shipment) {
        // ha, another gotcha case:
        // letterpress-only orders...
        printingPlusShipDate = shipments.small_shipment.ship_by_date;
        printingPlusArrivalDate = ShineShipping.instance().estimateArrivalDate(shipments.small_shipment, order);
    }
    function shipmentHeaderRow(label) {
        return <div className={classes.shipmentHeaderRow}><Typography className={classes.label}>{label}</Typography></div>;
    }

    function shipmentBlockRow(label, text) {
        return <div className={classes.shipmentBlockRow}><Typography>{label} {text}</Typography></div>;
    }

    let display = null;
    if (order.has_letterpress) {
        const letterpressShipDate = shipments.letterpress_shipment.ship_by_date;
        const letterpressArrivalDate = ShineShipping.instance().estimateArrivalDate(shipments.letterpress_shipment, order);
        display = (
            <div className={classes.shipment}>
                {
                    moreThanOneShipment && shipmentHeaderRow('Stationery')
                }
                {
                    shipmentBlockRow('Ship Date:', moment(letterpressShipDate).format(dateFormat))
                }
                {
                    letterpressArrivalDate
                    && order.country === 'US'
                    && shipmentBlockRow('Arrival Date:', moment(letterpressArrivalDate).format(dateFormat))
                }
                {
                    moreThanOneShipment
                    && <React.Fragment>
                        {shipmentHeaderRow(extraShipmentLabels.join(', '))}
                        {
                            printingPlusShipDate
                            && shipmentBlockRow('Ship Date:', moment(printingPlusShipDate).format(dateFormat))
                        }
                        {
                            printingPlusArrivalDate
                            && order.country === 'US'
                            && shipmentBlockRow('Arrival Date:', moment(printingPlusArrivalDate).format(dateFormat))
                        }
                    </React.Fragment>
                }
            </div>
        );
    } else {
        display = (
            <div className={classes.shipment}>
                {
                    printingPlusShipDate
                    && shipmentBlockRow('Ship Date:', moment(printingPlusShipDate).format(dateFormat))
                }
                {
                    printingPlusArrivalDate
                    && order.country === 'US'
                    && shipmentBlockRow('Arrival Date:', moment(printingPlusArrivalDate).format(dateFormat))
                }
            </div>
        );
    }

    return (
        <div>
            <Typography>PRINTING SPEED: {printSpeedText}</Typography>
            <Typography>SHIPPING SPEED: {shipSpeedText}</Typography>
            {display}
        </div>
    );
};

UnstyledShipments.defaultProps = {
    classes: {},
};

UnstyledShipments.propTypes = {
    classes: PropTypes.any,
    order: PropTypes.any.isRequired,
};

export const Shipments = withStyles(styles)(UnstyledShipments);
