import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from 'shine-ui-react';
import { ShineProducts } from 'shine-products-v2';

import clsx from 'clsx';

const styles = () => ({
    root: {
    },
    productName: {
        margin: '1.2rem 0px 0.8rem',
        fontWeight: 500,
    },
    listItem: {
        lineHeight: '2rem',
    },
    listSubItem: {
        paddingLeft: '1rem',
        '&::before': {
            content: '\' - \'',
        },
    },
});

export const UnstyledProductDetails = (props) => {
    const {
        className,
        classes,
        product,
        ignoreQuantity,
    } = props;

    const productDetails = ShineProducts.instance().propertiesOf(product);

    const product_name = `${product.design_name ? product.design_name : ''} ${product.product_type}`;

    return (
        <div className={clsx(classes.root, className)}>
            <Typography variant='h5' className={classes.productName} gutterBottom>{product_name}</Typography>
            {
                productDetails.map(({
                    label,
                    value,
                    delimiter,
                    level,
                }, index) => {
                    // for orders being printed at home, quantity is irrelevant
                    const str = ignoreQuantity && String(value).match(/\d+/) ? label : `${label}${delimiter}${value}`;

                    return (
                        <Typography
                            key={str + '-' + index}
                            className={
                                clsx(
                                    classes.listItem,
                                    {
                                        [classes.listSubItem]: level > 0,
                                    },
                                )
                            }>
                            {str}
                        </Typography>
                    );
                })
            }
        </div>
    );
};

UnstyledProductDetails.defaultProps = {
    classes: {},
    ignoreQuantity: false,
    product: {},
};

UnstyledProductDetails.propTypes = {
    classes: PropTypes.any,
    className: PropTypes.string,
    ignoreQuantity: PropTypes.bool,
    product: PropTypes.any.isRequired,
};

export default withStyles(styles)(UnstyledProductDetails);
