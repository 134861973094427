const {
    ShineCatalog,
    ShineOrders,
    ShinePricing,
    ShinePrinting,
    ShineProducts,
    ShineShipping,
} = require('shine-products-v2');

const ShineCatalogConfig = require('shine-products-v2/build/config/ShineCatalogConfig.json');
const ShineOrdersConfig = require('shine-products-v2/build/config/ShineOrdersConfig.json');
const ShinePricingConfig = require('shine-products-v2/build/config/ShinePricingConfig.json');
const ShinePrintingConfig = require('shine-products-v2/build/config/ShinePrintingConfig.json');
const ShineProductsConfig = require('shine-products-v2/build/config/ShineProductsConfig.json');
const ShineShippingConfig = require('shine-products-v2/build/config/ShineShippingConfig.json');

ShineCatalog.initialize(ShineCatalogConfig);
ShineOrders.initialize(ShineOrdersConfig);
ShinePricing.initialize(ShinePricingConfig);
ShineProducts.initialize(ShineProductsConfig);
ShinePrinting.initialize(ShinePrintingConfig);
ShineShipping.initialize(ShineShippingConfig);
