import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, ThemeProvider, makeShineTheme } from 'shine-ui-react';

const theme = makeShineTheme();

const Styler = (props) => (<ThemeProvider theme={theme}>
    <CssBaseline>
        {props.children}
    </CssBaseline>
</ThemeProvider>
);
Styler.propTypes = {
    children: PropTypes.any,
};
export default Styler;
