import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles, ShineLogo, Box, Typography,
} from 'shine-ui-react';
import clsx from 'clsx';
import moment from 'moment-business-days';

moment.locale('America/New_York');

const styles = (theme) => ({
    root: {
    },
    header: {
        height: 149,
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        position: 'relative',
        marginBottom: 32,
    },
    line: {
        height: 'calc(100% - 31px)',
        width: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        borderBottom: `1px solid ${theme.palette.grey.light}`,
        zIndex: 0,
    },
    logo: {
        height: 80,
        backgroundColor: 'white',
        zIndex: 1,
        padding: '0px 10px',
    },
    body: {
        minHeight: 500,
        padding: [0, 48],
        maxWidth: 1024,
        margin: [0, 'auto'],
    },
    bodyBox: {
        textAlign: 'center',
        margin: [0, 0, 16],
    },
    approvalBoxBody: {
        maxWidth: 500,
        margin: [0, 'auto'],
        '& p:first-child': {
            marginBottom: '1rem',
        },
    },
    getInTouchBox: {
        '& $contact': {
            margin: [32, 0, 0],
            '@media (min-width: 456px)': {
                flexWrap: 'nowrap',
                justifyContent: 'space-evenly',
            },
        },

    },
    shippingAndPrinting: {
        minHeight: 100,
    },
    orderSummary: {
        minHeight: 100,
    },
    footer: {
        position: 'relative',
        width: '100%',
    },
    footerContainer: {
        padding: [8, 48, 0],
        maxWidth: 1024,
        margin: [0, 'auto'],
        position: 'relative',
        '& > $contact': {
            margin: [0, 0, 32],
        },
    },
    greyLine: {
        position: 'absolute',
        width: '100%',
        left: 0,
        top: 0,
        borderTop: `1px solid ${theme.palette.grey.light}`,
    },
    contact: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        '@media (min-width: 768px)': {
            justifyContent: 'flex-end',
            '& > div': {
                width: 'auto',
            },
        },
    },
    contactItem: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'nowrap',
        margin: 8,
        '& svg': {
            height: 11,
            marginRight: 6,
        },
    },
    boxContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        maxWidth: 1000,
        '@media (min-width: 768px)': {
            justifyContent: 'space-between',
        },
    },
    footerBox: {
        width: '100%',
        margin: [0, 0, 16, 0],
        '@media (min-width: 768px)': {
            width: '48%',
        },
    },
    lockIcon: {
        height: 11,
    },
    copyright: {
        width: '100%',
        textAlign: 'center',
        '& p': {
            fontSize: '0.6rem',
        },
    },
});

export const UnstyledApprovalPage = (props) => {
    const { classes } = props;
    const [timestamp, updateTimestamp] = useState(moment().format('hh:mm:ss a'));
    useEffect(() => {
        setTimeout(() => updateTimestamp(moment().format('hh:mm:ss a')), 1000);
    }, [timestamp]);
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.line} />
                <div className={classes.logo}>
                    <ShineLogo width={237} />
                </div>
            </div>
            <div className={classes.body}>
                {props.children}
            </div>
            <div className={classes.footer}>
                <div className={classes.greyLine} />
                <div className={classes.footerContainer}>
                    <div className={classes.contact}>
                        <div className={classes.contactItem}>
                            <div>
                                <i data-fa-transform="flip-h" className="fas fa-phone" />
                            </div>
                            <div>
                                <a href="tel:+18009743086"><Typography variant="subtitle2"> 1-800-974-3086</Typography></a>
                            </div>
                        </div>
                        <div className={classes.contactItem}>
                            <i className="fas fa-envelope" />
                            <a href="mailto:hello@shineweddinginvitations.com">
                                <Typography variant="subtitle2">EMAIL US</Typography>
                            </a>
                        </div>
                    </div>
                    <div className={classes.boxContainer}>
                        <Box
                            className={classes.footerBox}
                            header={<Typography>YOUR WEDDING DAY STARTS AT SHINE</Typography>}
                            body={
                                <Typography>
                                    Your wedding day starts with your invitation.
                                    Make an impression with high quality paper,
                                    state of the art printing, and clean,
                                    modern and elegant design.
                                </Typography>
                            }
                        />

                        <Box
                            className={classes.footerBox}
                            header={<Typography>SHOP WITH CONFIDENCE</Typography>}
                            body={
                                <Typography>
                                    Satisfaction Guaranteed • Safe & Secure Checkout <i className={clsx(classes.lockIcon, 'fas fa-lock')} />
                                </Typography>
                            }
                        />
                        <div className={classes.copyright}>
                            <Typography align='center' variant='body1'>
                                © 2019. SHINE WEDDING INVITATIONS | LUXURY WEDDING INVITATIONS FOR MODERN WEDDINGS. | ALL RIGHTS RESERVED.
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

UnstyledApprovalPage.defaultProps = {
    classes: {},
};

UnstyledApprovalPage.propTypes = {
    classes: PropTypes.any,
    children: PropTypes.any,
};

export const ApprovalPageContainer = withStyles(styles)(UnstyledApprovalPage);

const UnstyledNoOrderFoundBox = ({ classes }) => (
    <Box
        data-cy='unauthorized'
        className={classes.bodyBox}
        header={<Typography variant='h1'>NO ORDER FOUND</Typography>}
        body={
            <a href="https://www.shineweddinginvitations.com/">
                <img width='200' alt="Shine Wedding Invitations"
                    src="https://firebasestorage.googleapis.com/v0/b/shineordermanager.appspot.com/o/email_template_images%2Flogo.jpg?alt=media&token=3c20b1c0-fb44-4afb-9eb7-20b93b4eda12" />
                <p>Click here to return to Shine Wedding Invitations</p>
            </a>
        }
    />
);
UnstyledNoOrderFoundBox.propTypes = {
    classes: PropTypes.any,
};
export const NoOrderFoundBox = withStyles(styles)(UnstyledNoOrderFoundBox);

const UnstyledErrorBox = ({ classes }) => (
    <Box
        data-cy='error'
        className={classes.bodyBox}
        header={<Typography variant='h1'>OOPS!</Typography>}
        body={
            <a href="https://www.shineweddinginvitations.com/">
                <img width='200' alt="Shine Wedding Invitations"
                    src="https://firebasestorage.googleapis.com/v0/b/shineordermanager.appspot.com/o/email_template_images%2Flogo.jpg?alt=media&token=3c20b1c0-fb44-4afb-9eb7-20b93b4eda12" />
                <p>Something went wrong...</p>
            </a>
        }
    />
);
UnstyledErrorBox.propTypes = {
    classes: PropTypes.any,
};
export const ErrorBox = withStyles(styles)(UnstyledErrorBox);

const UnstyledApprovalReceivedBox = ({ classes }) => (
    <Box
        className={classes.bodyBox}
        header={<Typography variant='h1'>{'WE\'VE RECEIVED YOUR APPROVAL!'}</Typography>}
        body={
            <div className={classes.approvalBoxBody} data-cy="approved-box">
                <Typography>
                    {'We\'ve received your approval and a confirmation email has been sent. If you don\'t see the email within a few minutes, please check your spam folder.'}
                </Typography>
                <Typography>
                    Thank you for choosing Shine Wedding Invitations!
                </Typography>
            </div>
        }
    />
);
UnstyledApprovalReceivedBox.propTypes = {
    classes: PropTypes.any,
};
export const ApprovalReceivedBox = withStyles(styles)(UnstyledApprovalReceivedBox);

const UnstyledMoreQuestionsBox = ({
    classes,
    header = 'MORE QUESTIONS?',
}) => (
    <Box
        className={classes.bodyBox}
        header={<Typography variant='h1'>{header}</Typography>}
        body={
            <div className={classes.getInTouchBox}>
                <Typography>
                        Get in touch with us and we will be happy to help you!
                </Typography>
                <div className={classes.contact}>
                    <div className={classes.contactItem}>
                        <div>
                            <i data-fa-transform="flip-h" className="fas fa-phone" />
                        </div>
                        <div>
                            <a href="tel:+18009743086"><Typography> 1.800.974.3086</Typography></a>
                        </div>
                    </div>
                    <div className={classes.contactItem}>
                        <i className="fas fa-envelope" />
                        <a href="mailto:hello@shineweddinginvitations.com">
                            <Typography>EMAIL US</Typography>
                        </a>
                    </div>
                </div>
            </div>
        }
    />
);
UnstyledMoreQuestionsBox.propTypes = {
    classes: PropTypes.any,
    header: PropTypes.any,
};
export const MoreQuestionsBox = withStyles(styles)(UnstyledMoreQuestionsBox);
export const GotQuestionsBox = () => <MoreQuestionsBox header='GOT QUESTIONS?' />;
